<template>
  <v-data-table
    :headers="headers"
    :items="arPositions"
    class="dt-listing transparent"
    disable-pagination
    disable-sort
    hide-default-footer
  >
    <template #top>
      <v-toolbar color="grey lighten-2" flat>
        <v-toolbar-title v-text="value.name" />
        <v-spacer />
        <v-btn
          color="primary"
          :to="{ name: 'accountingentries.update', params: { id: value.id } }"
        >
          {{ $t("edit") }}
        </v-btn>
        <v-btn color="error" class="ml-4" @click="onDelete">
          {{ $t("remove") }}
        </v-btn>
      </v-toolbar>
    </template>

    <template #[`item.is_positive`]="{ item }">
      <span v-text="item.is_positive ? '+' : '-'" />
    </template>

    <template #[`item.config`]="{ item }">
      <accounting-description-select :value="item.config" view />
    </template>

    <template #[`item.debit`]="{ item }">
      <accounting-credit-debit-select :value="item.debit" view />
    </template>

    <template #[`item.credit`]="{ item }">
      <accounting-credit-debit-select :value="item.credit" view />
    </template>

    <template v-for="n in arColumns" #[`item.column_${n}`]="{ item }">
      <accounting-column-field
        :key="`aet.${item.sort_order}.${n}`"
        :column="n"
        :value="getColumn(item, n - 1)"
        disabled
      />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import { AccountingEntryDataExt, PartialNullable } from "@/types/utils";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";
import {
  AccountingEntry,
  AccountingEntryColumnData,
  AccountingEntryPositionData,
} from "@planetadeleste/vue-mc-gw";
import { forEach, get, max, range, sortBy } from "lodash";
import AccountingDescriptionSelect from "@/modules/accountingentries/components/AccountingDescriptionSelect.vue";
import AccountingCreditDebitSelect from "@/modules/accountingentries/components/AccountingCreditDebitSelect.vue";
import AccountingColumnField from "@/modules/accountingentries/components/AccountingColumnField.vue";

@Component({
  components: {
    AccountingColumnField,
    AccountingCreditDebitSelect,
    AccountingDescriptionSelect,
  },
})
export default class AccountingEntryTable extends Mixins(DataTableMixin) {
  @Prop(Object) readonly value!: AccountingEntryDataExt;

  get arPositions(): PartialNullable<AccountingEntryPositionData>[] {
    if (!this.value) {
      return [];
    }

    const obModel =
      this.value instanceof AccountingEntry
        ? this.value
        : new AccountingEntry(this.value);

    return sortBy(obModel.get("positions", []), ["sort_order"]);
  }

  get arColumns() {
    return range(1, this.nColumns + 1);
  }

  get nColumns(): number {
    let nCols = 5;

    if (this.value && this.value.positions.length) {
      nCols = 0;
      forEach(this.value.positions, (obPosition) => {
        nCols = max([nCols, obPosition.columns?.length || 0]) as number;
      });
    }

    return nCols;
  }

  getColumn(
    obItem: PartialNullable<AccountingEntryPositionData>,
    sValue: number
  ): Partial<AccountingEntryColumnData> | null {
    if (!obItem.columns?.length || obItem.columns.length <= sValue) {
      return null;
    }

    return get(obItem.columns, sValue);
  }

  async onDelete() {
    this.$emit("delete", this.value.id);
  }

  mounted() {
    this.headers = [];
    const arHeaders: DataTableHeader[] = [];

    for (let i = 1; i <= this.nColumns; i++) {
      arHeaders.push({
        text: this.$t("accounting.sub.n", { n: i }) as string,
        value: `column_${i}`,
        translated: true,
      });
    }

    arHeaders.push({
      text: "accounting.description",
      value: "config",
      cellClass: "w-max-200",
    });
    arHeaders.push({ text: "accounting.sign", value: "is_positive" });
    arHeaders.push({ text: "accounting.debit", value: "debit" });
    arHeaders.push({ text: "accounting.credit", value: "credit" });
    arHeaders.push({ text: "accounting.book", value: "book" });

    this.setDTHeaders(arHeaders);
  }
}
</script>
